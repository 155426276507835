import { texts } from "@utils/textMessages"
import { log } from "@utils/logger"
import { store } from "@utils/store"
import { expandWhere, flattenWhere } from "@utils/search"
import Session, { useSessionContext } from "supertokens-auth-react/recipe/session"
import { appInfo, dvcApiDomain } from "config/appInfo"
import useSWR, { mutate } from "swr"

export type User = {
    username?: string
    first_name?: string
    emails?: string[]
    email_notification?: boolean
    isThirdParty: boolean
}
const baseURL = dvcApiDomain

export async function getUser() {
    let user
    let error
    let token
    try {
        if (await Session.doesSessionExist()) {
            user = await fetch(`${appInfo.apiDomain}/api/user`, { headers: { ...(typeof window === "undefined" && { "User-Agent": "dvc-client" }) } }).then((res) => res.json())

            let accessTokenPayload = await Session.getAccessTokenPayloadSecurely()
            if (accessTokenPayload.sub !== undefined) {
                token = await Session.getAccessToken()
            } else {
                // This branch is only required if there are valid access tokens created before the update
                // It can be removed after the validity period ends
                token = accessTokenPayload.jwt
            }
        } else {
            error = texts.notAuthenticated
        }
    } catch (err) {
        error = err.toString()
    }
    if (error) {
        return Promise.reject(error)
    }
    return { token, user }
}

export function useUser() {
    const { loading } = useSessionContext()
    const swrRes = useSWR<{ user: User; token: string }>(loading ? null : `${appInfo.apiDomain}/api/user`, getUser)
    return { ...swrRes, isAuthenticated: !!swrRes?.data?.user, user: swrRes.data?.user, isLoading: swrRes.isLoading || loading }
}

export async function fetchUserSearches() {
    const { token, user } = await getUser()
    try {
        let searches = await fetch(`${baseURL}/listings/searches/${user.username}`, {
            headers: {
                Authorization: token,
                ...(typeof window === "undefined" && { "User-Agent": "dvc-client" })
            }
        }).then((res) => res.json())
        const flatSearches = Array.isArray(searches)
            ? searches.map((s) => {
                  return { ...s, search_query: { ...s?.search_query, where: { ...flattenWhere(s?.search_query?.where) } } }
              })
            : []
        store.set(store.k.searches, flatSearches)
        return flatSearches
    } catch (e) {
        return []
    }
}
export const useUserSearches = () => {
    const { user } = useUser()

    const { data: searches = [] } = useSWR(user?.username ? `${baseURL}/listings/searches` : null, {
        fetcher: fetchUserSearches
    })
    return searches
}

export async function saveUserSearches(body) {
    const expandedBody = { ...body, search_query: { ...body?.search_query, where: { ...expandWhere(body?.search_query?.where) } } }
    try {
        let { token } = await getUser()
        const url = `${baseURL}/listings/searches`
        return await fetch(url, {
            method: "POST",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
                ...(typeof window === "undefined" && { "User-Agent": "dvc-client" })
            },
            body: JSON.stringify(expandedBody)
        })
            .then((res) => res.json())
            .finally(() => {
                mutate(url)
            })
    } catch (err) {
        log(err)
        throw new Error(texts.somethingWentWrong)
    }
}

export async function updateUserSearches(body) {
    const expandedBody = { ...body, search_query: { ...body?.search_query, where: { ...expandWhere(body?.search_query?.where) } } }
    try {
        let { token } = await getUser()
        const url = `${baseURL}/listings/searches`
        return await fetch(url, {
            method: "PUT",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
                ...(typeof window === "undefined" && { "User-Agent": "dvc-client" })
            },
            body: JSON.stringify(expandedBody)
        })
            .then((res) => res.json())
            .finally(() => {
                mutate(url)
            })
    } catch (err) {
        log(err)
        throw new Error(texts.somethingWentWrong)
    }
}

export async function deleteUserSearches(created_at) {
    return await fetch(`${baseURL}/listings/searches/object/${created_at}`, {
        method: "DELETE",
        headers: {
            ...(typeof window === "undefined" && { "User-Agent": "dvc-client" })
        }
    })
        .then((res) => res.json())
        .finally(() => mutate(`${baseURL}/listings/searches`))
}

/**
 * @param created_at {string}
 * @return {Promise<any>}
 * @throws {any}
 */
export async function getSearchByCreatedAt(created_at) {
    const search = await fetch(`${baseURL}/listings/searches/search/${created_at}`, { headers: { ...(typeof window === "undefined" && { "User-Agent": "dvc-client" }) } }).then(
        (res) => res.json()
    )
    return { ...search, search_query: { ...search?.search_query, where: { ...flattenWhere(search?.search_query?.where) } } }
}

export function updateUserProps(newProps: Partial<{ email_notification: boolean }>) {
    return fetch(`${appInfo.apiDomain}/api/user`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            ...(typeof window === "undefined" && { "User-Agent": "dvc-client" })
        },
        body: JSON.stringify(newProps)
    })
}
